import React from "react";
import "./css/Charu_Chandan_BloodDrip_Unicode-Regular.ttf";
import swamp from "./images/swamp.jpg";
import swamp1 from "./images/swamp1.jpg";
import swamp2 from "./images/swamp2.jpg";
export default function App() {
  return (
    <main className="page-container">
      <section className="hero-container">
        <h1 className="title">
          {" "}
          <span className="first-letter">T</span>he Swamp is back!
        </h1>
      </section>
      <section className="info-container">
        <div className="info-box">
          <h3 className="info-title">Coming Soon!</h3>
          <p className="p-1">
            This site is currently under construction! Come back later for more
            information!
          </p>
        </div>
      </section>
    </main>
  );
}
